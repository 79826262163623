div[contenteditable="true"] {
  background: transparent;
}
div[contenteditable="true"]:focus {
  outline: none;
}

@media print {
  .noprint {
    visibility: hidden;
  }
}
